<template>
    <default-template>
        <v-layout justify-center align-start>
            <v-responsive width="100wv">
                <div id="sub-wrap">
                    <div class="sub-contents">
                        <div class="container">
                            <div class="sub_head_title">
                                <h1>타임몰 회사소개</h1>
                            </div>
                            <div class="about-section">
                                <div class="img-wrap"><img src="/images/timemall/about/about-img.png" alt="이미지"></div>
                                <h2 class="page-title"><strong>한국상품권협회</strong> 공식 쇼핑몰</h2>
                                <div class="icon-plus"></div>
                                <p class="title-small6 color-point">협회인증업체 누구나 자유로이 이용이 가능하며 안전결제 실시간 핀번호 전송방식 또한 <br class="pc"/>타임몰만의 특화된 간편충전기능까지 제공해드리고 있습니다.</p>
                            </div>
                            <div class="about-section2">
                                <div class="about-banner">
                                    <div class="about-banner__con">
                                        <h3 class="page-title2">타임몰 <span class="color-point">24시간 고객센터</span> 운영으로
                                        </h3>
                                        <p class="title-small6 color-dark4">구입문의나 오류등 바로바로 확인이 가능하며 <br class="pc" />신속하게
                                            응대해드리겠습니다.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="about-section3">
                                <h2 class="page-title"><strong>타임몰 협력사</strong> 등록안내</h2>
                                <div class="title-small4 color-dark4">한국상품권협회 협력사 등록시,</div>
                                <ul class="about-list mx-n2 mx-lg-n4 pl-0">
                                    <li class="px-2 px-lg-4">
                                        <div class="about-list-icon" style="background-image: url('/images/pluspin/sub/about/about-icon.png');">
                                        </div>
                                        <div class="about-list-txt">상품권매매업 운영<br><strong>노하우 컨설팅</strong></div>
                                    </li>
                                    <li class="px-2 px-lg-4">
                                        <div class="about-list-icon"
                                            style="background-image: url('/images/pluspin/sub/about/about-icon2.png');">
                                        </div>
                                        <div class="about-list-txt">모든 상품권<br><strong>구입채널 안내</strong></div>
                                    </li>
                                    <li class="px-2 px-lg-4">
                                        <div class="about-list-icon"
                                            style="background-image: url('/images/pluspin/sub/about/about-icon3.png');">
                                        </div>
                                        <div class="about-list-txt">협회만의<br><strong>특화 서비스</strong></div>
                                    </li>
                                </ul>
                            </div>
                            <div class="about-section4">
                                <div class="about-sec4-left">
                                    <img src="/images/timemall/about/about-logo.png" alt="한국상품권협회&pluspin"></div>
                                <div class="about-sec4-right">
                                    <ul class="about-list2">
                                        <li><strong class="about-list2__tit"><i class="about-list__icon"
                                                    style="background-image: url('/images/pluspin/icon/icon-phone.png');"></i>대표번호</strong>
                                            <span class="about-list2__txt">010-4122-0080</span></li>
                                        <li><strong class="about-list2__tit"><i class="about-list__icon"
                                                    style="background-image: url('/images/pluspin/icon/icon-chat.png');"></i>카톡상담</strong>
                                            <span class="about-list2__txt">w0030</span></li>
                                        <li><strong class="about-list2__tit"><i class="about-list__icon"
                                                    style="background-image: url('/images/pluspin/icon/icon-edit.png');"></i>이메일</strong>
                                            <span class="about-list2__txt">byunpol84@naver.com</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-responsive>
        </v-layout>
    </default-template>
</template>
<script>
import { mapState } from 'vuex';
import DefaultTemplate from "../DefaultTemplate.vue";
export default {
    components: {
        DefaultTemplate
    },
    computed: {
        ...mapState(["siteInfo"]),
    }
}
</script>

<!-- reset.css -->
<style scoped>
/* ========== Style Default ========== */
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  font-family: NotoKR, "Apple SD Gothic Neo", Helvetica, sans-serif, Arial;
  color: #333;
  letter-spacing: -0.04em;
  word-break:keep-all;
  white-space:-moz-pre-wrap;
  white-space:-pre-wrap;
  white-space:-o-pre-wrap;
  word-wrap:break-word;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
img { display: block; margin: 0 auto; border: none; max-width: 100%; }
ul { list-style: none; }
a:link, a:hover, a:visited, a:active { color: inherit; text-decoration: none; }
h1 { font-weight: 500; }
h2 { font-weight: 500; }
h3 { font-weight: 500; }
h4 { font-weight: 500; }
h5 { font-weight: 500; }
h6 { font-weight: 500; }
strong { font-weight: 500; }
button { font-family: inherit; background: none; border: none; cursor: pointer; outline: none; }
table { border-collapse: collapse; font-size: 15px; }
input { outline: none; }
input[type="password"] { font-family: NotoKR, Arial; }
input, textarea, select { font-family: inherit; padding-left: 10px; border: 1px solid #e5e5e5; }
label, select, button, input[type="button"], input[type="reset"], input[type="submit"], input[type="radio"], input[type="checkbox"] { cursor: pointer; }
label{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
option { font-size: 16px; }
</style>

<!-- layout.css -->
<style scoped>

/* Wrap - Container */
#wrap { padding: 0; }
#sub-wrap { padding: 60px 0 0; overflow: hidden;}
.container { margin: auto; padding: 0 15px; max-width: 1230px; }
.container-45 { margin: auto; max-width: 1095px; }
.store-contents { padding: 0px 0 110px; }
.sub-contents { padding: 0px 0 150px; }
.store-wrap { padding: 150px 0 100px; }

.mt-none { margin-top: 0 !important; }
.mb-none { margin-bottom: 0 !important; }
.pt-none { padding-top: 0 !important; }
.pb-none { padding-bottom: 0 !important; }

@media (max-width: 1024px){
    #wrap { padding: 50px 0 0; }
    #sub-wrap { padding: 50px 0 0; }
    .store-wrap { padding: 110px 0 40px; }
    .sub-contents { padding: 45px 0 100px; }
    .store-contents { padding: 45px 0 100px; }
}
@media (max-width: 768px){
    .sub-contents {
        padding: 30px 0 70px;
    }
}




/* 마이페이지 */
.my-contents { padding-top: 30px; }
.my:after { content: ''; display: block; clear: both; }
.my-l { float: left; width: 23.8%; padding-right: 60px; }
.my-r { float: left; width: 76.2%; }
.my-ul li a { display: block; padding: 0 10px; height: 40px; line-height: 40px; font-size: 15px; color: #666666; }
.my-ul li.on a,
.my-ul li a:hover { color: #454e6c; font-weight: 500; background: url('/images/pluspin/icon/my-on.png') no-repeat right 15px center; }

.head_title { padding: 12px 12px 12px 40px; text-align: left;background: url('/images/pluspin/icon/icon_title.png') no-repeat center left / 22px; background-position-x: 8px; }
.head_title h3 { margin-bottom: 0; font-size: 18px; line-height: 23px; color: #333333; font-weight: 500; }

.sub_head_title {
    position: relative;
    padding-top:60px;
    margin-bottom: 50px;
}
.sub_head_title .fRight { display: none; }
.sub_head_title .fLeft{
    float: none;
    display: block;
    text-align: center;
}
.sub_head_title h1 {
    font-size: 52px;
    font-weight: 600;
    line-height: 1;
    color: #e72154;
}
/* .sub_head_title h1:before{
    content:'';
    position: absolute;
    bottom:0;
    left:50%;
    transform: translate(-50%,0);
    display:block;
    width:8px;
    height:1px;
    background-color: #e72154;
} */
.sub_head_title .list { margin-top: 8px; }
.sub_head_title .list a { padding-right: 20px; background: url('/images/pluspin/icon/icon_sub_arrow.png') no-repeat center right 5px; cursor: default; }
.sub_head_title .list a span { font-size: 14px; color: #666666; }
.sub_head_title .list a:first-child span { margin-top: 3.5px; display: inline-block; vertical-align: ㅡㅑㅇ읻; width: 16px; height: 13px; font-weight: 400; /*background: url('/images/pluspin/icon/icon_sub_nav.png') no-repeat center;*/ }
.sub_head_title .list a:last-child { padding-right: 0; background: none; }

@media (max-width: 1024px){
    .head_title { background-size: auto 20px; }
    .head_title h3 { font-size: 16px; }
    .sub_head_title {
        padding-top: 20px;
        margin-bottom: 30px;
    }
    .sub_head_title .fLeft { padding-left: 0; }
    .sub_head_title h1 { font-size: 26px; }
    .sub_head_title .fRight { display: none; }
    .join_input_tit_wrap h3 { font-size: 16px; }


    .my-l { display: none; }
    .my-r { width: 100%; padding-left: 0; }
    .s-top-text h4 { line-height: 29px; font-size: 22px; }
    .s-top-text p { font-size: 14px; }
    .s-top-text a { font-size: 14px; }
}
@media (max-width: 768px){
    .sub_head_title{margin-bottom: 20px;}
}

</style>

<!-- font.css -->
<style scoped>
/* COMMON */
.b { font-weight: bold; }

.align-left { text-align: left !important; }
.align-right { text-align: right !important; }
.align-center { text-align: center !important; }

.weight-light { font-weight: 200; }
.weight-medium { font-weight: 500; }
.weight-regular { font-weight: normal; }

.style-normal { font-style: normal; }

.point_color,
.color-point { color: #e72154 !important; }
.color-aqua { color: #0eb4ca !important; }
.color-sky { color: #00a0e9 !important; }
.color-blue { color: #044ab3 !important; }
.color-white { color: #fff !important; }
.color-lemon { color: #f9f7c3 !important; }
.color-orange{color:#fca932 !important;}
.color-dark { color: #000 !important; }
.color-dark1 { color: #111 !important; }
.color-dark2 { color: #222 !important; }
.color-dark3 { color: #333 !important; }
.color-dark4 { color: #444 !important; }
.color-grey5 { color: #555 !important; }
.color-grey6 { color: #666 !important; }
.color-grey7 { color: #777 !important; }
.color-grey8 { color: #888 !important; }
.color-grey9 { color: #999 !important; }
.color-o20 { color: rgba(255,255,255,.2) !important; }
.color-o30 { color: rgba(255,255,255,.3) !important; }
.color-o40 { color: rgba(255,255,255,.4) !important; }
.color-o50 { color: rgba(255,255,255,.5) !important; }
.color-o60 { color: rgba(255,255,255,.6) !important; }
.color-o70 { color: rgba(255,255,255,.7) !important; }
.color-o80 { color: rgba(255,255,255,.8) !important; }
.color-o90 { color: rgba(255,255,255,.9) !important; }


.title-xsmall1 { font-size: 11px; }
.title-xsmall2 { font-size: 12px; }
.title-xsmall3 { font-size: 13px; }
.title-xsmall4 { font-size: 14px; }
.title-xsmall5 { font-size: 15px; }
.title-xsmall6 { font-size: 16px; }
.title-xsmall7 { font-size: 17px; }
.title-xsmall8 { font-size: 18px; }
.title-xsmall9 { font-size: 19px; }
.title-small { font-size: 20px; }
.title-small1 { font-size: 21px; }
.title-small2 { font-size: 22px; }
.title-small3 { font-size: 23px; }
.title-small4 { font-size: 24px; }
.title-small5 { font-size: 25px; }
.title-small6 { font-size: 26px; }
.title-small7 { font-size: 27px; }
.title-small8 { font-size: 28px; }
.title-small9 { font-size: 29px; }
.title-regular { font-size: 30px; }
.title-regular1 { font-size: 31px; }
.title-regular2 { font-size: 32px; }
.title-regular3 { font-size: 33px; }
.title-regular4 { font-size: 34px; }
.title-regular5 { font-size: 35px; }
.title-regular6 { font-size: 36px; }
.title-regular7 { font-size: 37px; }
.title-regular8 { font-size: 38px; }
.title-regular9 { font-size: 39px; }
.title-medium { font-size: 40px; }
.title-medium1 { font-size: 41px; }
.title-medium2 { font-size: 42px; }
.title-medium3 { font-size: 43px; }
.title-medium4 { font-size: 44px; }
.title-medium5 { font-size: 45px; }
.title-medium6 { font-size: 46px; }
.title-medium7 { font-size: 47px; }
.title-medium8 { font-size: 48px; }
.title-medium9 { font-size: 49px; }
.title-large { font-size: 50px; }
.title-large1 { font-size: 51px; }
.title-large2 { font-size: 52px; }
.title-large3 { font-size: 53px; }
.title-large4 { font-size: 54px; }
.title-large5 { font-size: 55px; }
.title-large6 { font-size: 56px; }
.title-large7 { font-size: 57px; }
.title-large8 { font-size: 58px; }
.title-large9 { font-size: 59px; }
.title-xlarge { font-size: 60px; }
.title-xlarge1 { font-size: 61px; }
.title-xlarge2 { font-size: 62px; }
.title-xlarge3 { font-size: 63px; }
.title-xlarge4 { font-size: 64px; }
.title-xlarge5 { font-size: 65px; }
.title-xlarge6 { font-size: 66px; }
.title-xlarge7 { font-size: 67px; }
.title-xlarge8 { font-size: 68px; }
.title-xlarge9 { font-size: 69px; }

@media (max-width: 1024px){
    .title-xsmall1 { font-size: 10px; }
    .title-xsmall2 { font-size: 11px; }
    .title-xsmall3 { font-size: 12px; }
    .title-xsmall4 { font-size: 13px; }
    .title-xsmall5 { font-size: 14px; }
    .title-xsmall6 { font-size: 15px; }
    .title-xsmall7 { font-size: 16px; }
    .title-xsmall8 { font-size: 17px; }
    .title-xsmall9 { font-size: 17px; }
    .title-xsmall10 { font-size: 17px; }
    .title-small { font-size: 18px; }
    .title-small1 { font-size: 18px; }
    .title-small2 { font-size: 18px; }
    .title-small3 { font-size: 18px; }
    .title-small4 { font-size: 18px; }
    .title-small5 { font-size: 18px; }
    .title-small6 { font-size: 18px; }
    .title-small7 { font-size: 18px; }
    .title-small8 { font-size: 18px; }
    .title-small9 { font-size: 18px; }
    .title-regular { font-size: 20px; }
    .title-regular1 { font-size: 21px; }
    .title-regular2 { font-size: 22px; }
    .title-regular3 { font-size: 23px; }
    .title-regular4 { font-size: 24px; }
    .title-regular5 { font-size: 25px; }
    .title-regular6 { font-size: 26px; }
    .title-regular7 { font-size: 27px; }
    .title-regular8 { font-size: 28px; }
    .title-regular9 { font-size: 29px; }
    .title-medium { font-size: 30px; }
    .title-medium1 { font-size: 31px; }
    .title-medium2 { font-size: 32px; }
    .title-medium3 { font-size: 33px; }
    .title-medium4 { font-size: 34px; }
    .title-medium5 { font-size: 35px; }
    .title-medium6 { font-size: 36px; }
    .title-medium7 { font-size: 37px; }
    .title-medium8 { font-size: 38px; }
    .title-medium9 { font-size: 39px; }
    .title-large { font-size: 40px; }
    .title-large1 { font-size: 41px; }
    .title-large2 { font-size: 42px; }
    .title-large3 { font-size: 43px; }
    .title-large4 { font-size: 44px; }
    .title-large5 { font-size: 45px; }
    .title-large6 { font-size: 46px; }
    .title-large7 { font-size: 47px; }
    .title-large8 { font-size: 48px; }
    .title-large9 { font-size: 49px; }
    .title-xlarge { font-size: 50px; }
    .title-xlarge1 { font-size: 41px; }
    .title-xlarge2 { font-size: 42px; }
    .title-xlarge3 { font-size: 43px; }
    .title-xlarge4 { font-size: 44px; }
    .title-xlarge5 { font-size: 45px; }
    .title-xlarge6 { font-size: 46px; }
    .title-xlarge7 { font-size: 47px; }
    .title-xlarge8 { font-size: 48px; }
    .title-xlarge9 { font-size: 49px; }
}
@media (max-width: 768px){
    .title-xsmall5 { font-size: 13px; }
    .title-xsmall6 { font-size: 14px; }
    .title-xsmall7 { font-size: 15px; }
    .title-xsmall8 { font-size: 16px; }
    .title-xsmall9 { font-size: 16px; }
    .title-xsmall10 { font-size: 16px; }
    .title-small { font-size: 17px; }
    .title-small2 { font-size: 17px; }
    .title-small3 { font-size: 17px; }
    .title-small4 { font-size: 17px; }
    .title-small5 { font-size: 17px; }
    .title-small6 { font-size: 17px; }
    .title-small7 { font-size: 17px; }
    .title-small8 { font-size: 17px; }
    .title-small9 { font-size: 17px; }
    .title-small10 { font-size: 17px; }
    .title-regular { font-size: 18px; }
    .title-regular2 { font-size: 18px; }
    .title-regular3 { font-size: 18px; }
    .title-regular4 { font-size: 18px; }
    .title-regular5 { font-size: 18px; }
    .title-regular6 { font-size: 18px; }
    .title-regular7 { font-size: 18px; }
    .title-regular8 { font-size: 18px; }
    .title-regular9 { font-size: 18px; }
    .title-regular10 { font-size: 18px; }
    .title-medium { font-size: 20px; }
    .title-medium1 { font-size: 21px; }
    .title-medium2 { font-size: 22px; }
    .title-medium3 { font-size: 23px; }
    .title-medium4 { font-size: 24px; }
    .title-medium5 { font-size: 25px; }
    .title-medium6 { font-size: 26px; }
    .title-medium7 { font-size: 27px; }
    .title-medium8 { font-size: 28px; }
    .title-medium9 { font-size: 29px; }
    .title-large { font-size: 20px; }
    .title-large1 { font-size: 21px; }
    .title-large2 { font-size: 22px; }
    .title-large3 { font-size: 23px; }
    .title-large4 { font-size: 24px; }
    .title-large5 { font-size: 25px; }
    .title-large6 { font-size: 26px; }
    .title-large7 { font-size: 27px; }
    .title-large8 { font-size: 28px; }
    .title-large9 { font-size: 29px; }
    .title-xlarge { font-size: 30px; }
    .title-xlarge1 { font-size: 21px; }
    .title-xlarge2 { font-size: 22px; }
    .title-xlarge3 { font-size: 23px; }
    .title-xlarge4 { font-size: 24px; }
    .title-xlarge5 { font-size: 25px; }
    .title-xlarge6 { font-size: 26px; }
    .title-xlarge7 { font-size: 27px; }
    .title-xlarge8 { font-size: 28px; }
    .title-xlarge9 { font-size: 29px; }
}

.pr-xsmall { font-size: 14px; }
.pr-small { font-size: 15px; color: #555555; }
.pr { line-height: 24px; font-size: 16px; color: #555555; }
.pr-large { line-height: 26px; font-size: 17px; color: #555555; }
.pr-large2 { line-height: 26px; font-size: 18px; color: #555555; }
.pr-large3 { line-height: 26px; font-size: 19px; color: #555555; }
.pr-large4 { line-height: 28px; font-size: 20px; color: #555555; }
@media (max-width: 1024px){
    .pr-xsmall { font-size: 13px; }
    .pr-small { line-height: 24px; font-size: 14px; }
    .pr { line-height: 24px; font-size: 15px; }
    .pr-large { line-height: 24px; font-size: 16px; }
    .pr-large2 { line-height: 26px; font-size: 17px; }
    .pr-large3 { line-height: 28px; font-size: 18px; }
    .pr-large4 { line-height: 28px; font-size: 19px; }
}
@media (max-width: 768px){
    .pr-small { line-height: 24px; font-size: 13px; }
    .pr { line-height: 24px; font-size: 14px; }
    .pr-large { line-height: 24px; font-size: 15px; }
    .pr-large2 { line-height: 26px; font-size: 16px; }
    .pr-large3 { line-height: 28px; font-size: 16px; }
    .pr-large4 { line-height: 28px; font-size: 16px; }
}


.sub-title,
.sub-title-bor { line-height: 34px; font-size: 35px; color: #333333; }
.sub-title-bor { padding-bottom: 20px; border-bottom: 1px solid #ccc; }
.circle-title { padding-left: 34px; font-weight: 24; line-height: 40px; font-weight: 500; background: url('/images/pluspin/icon/circle-title.png') no-repeat left center; }

@media (max-width: 1024px){

}
@media (max-width: 768px){

}





/* Member */
.my-title { padding-bottom: 24px; line-height: 23px; font-size: 23px; color: #666666; font-weight: 500; border-bottom: 1px solid #cccccc; }
.form-title { padding-bottom: 10px; line-height: 20px; font-size: 20px; color: #333333; font-weight: 500; border-bottom: 1px solid #aaaaaa; }
.agree-title { border-bottom: none; }
.agree-title .check { font-size: 16px; color: #926d49; font-weight: 500; }
.form-title .check { float: right; }





/* Main */
.title { position: relative; text-align: center; line-height: 38px; font-size: 42px; font-weight: 200; }
.title + p { margin-top: 15px; text-align: center; font-size: 17px; }
.title-light { color: #fff !important; }
.title-light + p { color: rgba(255,255,255,.7) !important; }
.main-board-title { margin-bottom: 10px; line-height: 30px; font-size: 25px; }
.main-board-title a { display: block; background: url('/images/pluspin/icon/main-board-title.png') no-repeat right 1px center; }

@media (max-width: 1024px){
    .title { line-height: 18px; font-size: 23px; }
    .title + p { line-height: 16px; font-size: 15px; }
    .main-board-title { font-size: 20px; background-size: auto 30px; }
}
@media (max-width: 768px){
    .title { line-height: 15px; font-size: 20px; }
    .main-board-title { font-size: 18px; background-size: auto 25px; }
}

</style>

<!-- sub.css -->
<style scoped>
/* COMMON */
.sub-visual { margin-top: -60px; height: 300px; text-align: center; }
.sv1 { background: url('/images/pluspin/sub/sv1.jpg') no-repeat center / cover; }
.sv2 { background: url('/images/pluspin/sub/sv2.jpg') no-repeat center / cover; }
.sv3 { background: url('/images/pluspin/sub/sv3.jpg') no-repeat center / cover; }
.sv4 { background: url('/images/pluspin/sub/sv4.jpg') no-repeat center / cover; }
.sv5 { background: url('/images/pluspin/sub/sv5.jpg') no-repeat center / cover; }
.sv6 { background: url('/images/pluspin/sub/sv6.jpg') no-repeat center / cover; }
.sub-visual h2 { font-size: 40px; font-weight: 500; }
.sub-visual p { margin-bottom: 13px; line-height: 26px; font-size: 20px; }
.sub-visual p:last-child { margin-bottom: 0; }
.sub-visual-dark h2 { color: #333 !important; }
@media (max-width: 1024px){
    .sub-visual { margin-top: 0; height: 250px; }
    .sub-visual h2 { font-size: 30px; }
    .sub-visual p { font-size: 18px; }
}
@media (max-width: 768px){
    .sub-visual { height: 150px; }
    .sub-visual h2 { font-size: 20px; }
    .sub-visual p { font-size: 16px; }
}


/* 약관 */
@media(max-width:768px){
    .terms-area * { font-size: 13px !important; }
}


/* Sub Tab List */
/* .sub-tab-area { z-index: 2; position: relative; margin: -55px auto 0; width: 100%; background: rgba(4,74,179,.8); }
.sub-tab-list { margin: auto; max-width: 1050px; width: 100%; }
.sub-tab-list:after { content: ''; display: block; clear: both; }
.sub-tab-list li { float: left; text-align: center; }
.sub-tab-list li a { display: block; height: 55px; font-size: 18px; font-weight: 200; color: rgba(255,255,255,1); }
.sub-tab-list li:last-child a { }
.sub-tab-list li a:hover { background: rgba(0,0,0,.1); }
.sub-tab-list li.on a { color: #333333; background: #fff; }
.sub-tab-list li a:hover span:after,
.sub-tab-list li.on a span:after { width: 100%; }
.l-1 li { width: 100%; }
.l-2 li { width: 50%; }
.l-3 li { width: 33.33333%; }
.l-4 li { width: 25%; }
.l-5 li { width: 20%; }
.l-6 li { width: 16.66666%; }

.i-emergency { display: inline-block; vertical-align: -5px; margin-right: 5px; width: 32px; height: 25px; background: url('/images/pluspin/icon/i-emergency.png') no-repeat; }

@media (max-width: 1024px){
}
@media (max-width: 768px){
    .sub-tab-area { margin-top: -40px; padding: 0 15px; }
    .sub-tab-list li a { height: 40px; line-height: 16px; font-size: 14px; }
    .sub-tab-list li.on a { font-size: 14px; }
}
@media (max-width: 550px){
    .sub-tab-area { margin-top: 0; padding: 0; }
    .sub-tab-list { background: rgba(0,0,0,.2); }
    .sub-tab-list li a,
    .sub-tab-list li.on a { font-size: 13px; }
    .sub-tab-list li a { border-left-color: rgba(255,255,255,.2); }
    .l-5 li { width: 33.33333%; }
    .l-5 li a { border-bottom: 1px solid rgba(255,255,255,.2); }
    .l-5 li:last-child a { position: relative; border-right: none; }
    .l-5 li:last-child a:before { content: ''; position: absolute; top: 0; right: -1px; width: 1px; height: 100%; background: rgba(255,255,255,.2); }
} */


/* 준비중(coming_soon) */
.coming_soon { display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center;
     width: 100%; height: 500px; background-color: #e5e5e5; }
.coming_soon h2 { font-size: 40px; }

@media (max-width:1024px){

}
@media (max-width:768px){

}

/* Sub Common */
.page-title{
    font-size: 42px;
    font-weight: 300;
    color: #161616;
    margin-bottom: 15px;
}
.page-title strong{
    font-weight: 600;
}
.page-title2{
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 15px;
}
@media (max-width:1024px){
    .page-title{
        font-size: 28px;
    }
    .page-title2 {
        font-size: 26px;
        margin-bottom: 10px;
    }
}
@media (max-width:992px){
}
@media (max-width:768px){
    .page-title{
        font-size: 24px;
    }
    .page-title2 {
        font-size: 22px;
    }
}
@media (max-width:576px){
}


/* 회사소개 */
[class*="about-section"]{
    padding-top: 150px;
}
.about-section{
    text-align: center;
    padding-top: 0;
}
.about-section .img-wrap{
    margin-bottom: 80px;
}
.icon-plus{
    position: relative;
    min-height: 24px;
    margin:40px 0;
}
.icon-plus:before, .icon-plus:after{
    content:'';
    position: absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%); -ms-transform: translate(-50%,-50%); transform: translate(-50%,-50%);
    display: block;
    background-color: #e72154;
}
.icon-plus:before{
    width: 24px;
    height: 8px;
}
.icon-plus:after{
    width: 8px;
    height: 24px;
}
.about-section .title-small6{
    line-height: 1.8;
}

.about-banner{
    display: flex;
    align-items: center;
    position: relative;
    min-height: 310px;
}
.about-banner:before, .about-banner:after{
    content:'';
    position: absolute;
    left:50%;
    -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0);
    z-index: -1;
    display: block;
    width: 1920px;
}
.about-banner:before{
height: 100%;
    background-color: #f7f7f7;
}
.about-banner:after{
    height: 331px;
    bottom:-70px;
    background-repeat: no-repeat;
    background-position:bottom 0 right 110px;
    background-image:url(/images/nextpin/sub/about/about-bg.png);
}
.about-banner .title-small6{
    line-height: 1.8;
}

.about-section3{
    text-align: center;
}
.about-list{
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
}
.about-list >li{
    position: relative;
    width: 33.3333%;
}
.about-list >li:before{
    content:'';
    position: absolute;
    top:42px;
    left:0;
    display: block;
    width: 1px;
    height: 60px;
    background-color: #e2e2e2;
}
.about-list >li:first-child:before{display: none;}
.about-list-icon{
    width: 100%;
    height: 131px;
    margin-bottom: 30px;
    background-repeat: no-repeat;
    background-position:center;
}
.about-list-txt{
    font-size: 24px;
    color: #161616;
}
.about-list-txt strong{
    font-weight: 600;
}

.about-section4{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    min-height: 452px;
}
.about-section4:before{
    content: '';
    position: absolute;
    bottom:-150px;
    left:50%;
    z-index: -2;
    -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0);
    width: 1920px;
    height: 200px;
    background-color: #fff4f7;
}
.about-sec4-left, .about-sec4-right{
    padding:0;
}
.about-sec4-left{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 57.6666%;
}
.about-sec4-left::before{
    content:'';
    position: absolute;
    top:0;
    right:0;
    z-index: -1;
    display: block;
    width: 1052px;
    height: 100%;
    border:10px solid #f7f7f7;
    border-left:0;
    background-color: #fff;
}
.about-sec4-right{
    width:calc(100% - 57.6666%);
    display: flex;
    align-items: center;
    padding-left: 70px;
    padding-bottom: 50px;
}
.about-list2{
    width: 100%;
}
.about-list2 >li{
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}
.about-list2 >li:last-child{
    padding-bottom:0;
}
.about-list2__tit{
    display: flex;
    align-items: center;
    font-size: 26px;
    font-weight: 600;
    color: #e72154;
    margin-right: 20px;
}
.about-list__icon{
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
.about-list2__txt{
    font-size: 26px;
    font-weight: 200;
    color: #161616;
}
@media (max-width:1024px){
    [class*="about-section"]{
        padding-top: 100px;
    }
    .about-section{
        padding-top: 0;
    }
    .about-section .img-wrap{
        margin-bottom: 50px;
    }
    .icon-plus{
        margin:20px 0;
    }
    .about-banner{
        min-height: 250px;
    }
    .about-list-txt{
        font-size: 18px;
    }
    .about-section4{
        min-height: 350px;
    }
    .about-sec4-left img{
        padding: 0 35px 0 20px;
    }
    .about-sec4-right{
        padding-left: 30px;
    }
    .about-list2__tit, .about-list2__txt{
        font-size: 20px;
    }
}
@media (max-width:992px){
    .about-section4{
        min-height:auto;
    }
    .about-section4:before, .about-sec4-left::before{display:none;}
    .about-sec4-left, .about-sec4-right{
        width: 100%;
    }
    .about-sec4-left{
        justify-content: center;
        border:10px solid #f7f7f7;
        margin-bottom: 30px;
    }
    .about-sec4-left img {
        padding: 35px;
    }
    .about-sec4-right{
        padding:0;
    }
}
@media (max-width:768px){
    [class*="about-section"] {
        padding-top: 70px;
    }
    .about-section{
        padding-top: 0;
    }
    .about-section .img-wrap{
        margin-bottom: 30px;
    }
    .about-banner:after{display:none;}
    .about-list{
        margin-top:50px;
    }
    .about-list2 >li{
        padding-bottom: 10px;
    }
    .about-list-icon{
        height: 90px;
        background-size: auto 90px;
        margin-bottom: 20px;
    }
    .about-list-txt{
        font-size: 16px;
    }
    .about-list2__tit, .about-list2__txt {
        font-size: 18px;
    }
}
@media (max-width:576px){
    [class*="about-section"] {
        padding-top: 50px;
    }
    .about-section{
        padding-top: 0;
    }
    .about-sec4-left{
        margin-bottom: 20px;
    }
    .about-list{
        margin-top:35px;
    }
    .about-list-icon{
        height: 50px;
        background-size: auto 50px;
        margin-bottom: 10px;
    }
    .about-list-txt{
        font-size: 14px;
    }
    .about-list2__tit, .about-list2__txt {
        font-size: 16px;
    }
    .about-list__icon{
        width: 20px;
        height: 20px;
        background-size: contain;
        margin-right: 5px;
    }
}

.location{
    display: flex;
    flex-wrap: wrap;
}
.location-left{
    width: 50%;
}
.location-right{
    width:50%;
}
.list-style li{
    position: relative;
    min-height: 27px;
    font-size: 16px;
    color: #707070;
    padding-left: 125px;
    margin-bottom: 25px;
}
.list-style li:last-child{
    margin-bottom: 0;
}
.list-style li em{
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    color: #fff;
    background-color: #e72154;
    border-radius: 100px;
    padding: 2px 10px;
}
.list-style li:nth-child(even) em{
    background-color: #fca932;
}
.map{
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
}
@media(min-width:1025px){
}
@media(max-width:1024px){
}
@media(max-width:992px){
    .location-left, .location-right{
        width:100%;
    }
    .location-left{
        order: 2;
    }
    .location-right{
        order:1;
        margin-bottom: 20px;
    }
}
@media(max-width:768px){
    .list-style li, .list-style li em{
        font-size: 14px;
    }
    .list-style li{
        padding-left: 100px;
        margin-bottom: 10px;
    }
    .list-style li em{
        width: 90px;
    }
}
@media(max-width:576px){
}


/* 대량거래/체인점문의 */
.inquiry-section{
    padding-bottom: 70px;
    margin-bottom: 70px;
    border-bottom:1px solid #ddd;
}
.inquiry-section .img-wrap{
    margin-bottom: 80px;
}
.inquiry-section .page-text{
    margin-bottom: 90px;
}
.inquiry-section .img-wrap >p{
    font-size: 16px;
    color: #333;
    margin-top: 30px;
}
.inquiry-info{
    display: flex;
    flex-wrap: wrap;
}
.inquiry-info-left,
.inquiry-info-right{
    width:50%;
}
.inquiry-info-left{
    padding-right: 80px;
}
.inquiry-info-left img{
    margin-left: auto;
}
.inquiry-info-right p{
    font-size: 16px;
    color: #333;
    line-height: 1.4;
    margin-bottom: 5px;
}
.inquiry-info-right p >strong{
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    padding: 3px 8px;
    color: #555;
    background-color: #f5f5f5;
    margin-bottom: 10px;
}
.inquiry-info-right p >em{
    display: inline-block;
    min-width: 75px;
    font-style: normal;
    font-weight: 600;
}
@media(min-width:1025px){
}
@media(max-width:1024px){
}
@media(max-width:992px){
    .inquiry-section{
        padding-bottom:30px;
        margin-bottom: 30px;
    }
    .inquiry-section .img-wrap{
        margin-bottom: 50px;
    }
    .inquiry-section .img-wrap >p{
        font-size: 14px;
    }
    .inquiry-section .page-text{
        margin-bottom: 50px;
    }
}
@media(max-width:768px){
    .inquiry-section .img-wrap{
        margin-bottom: 30px;
    }
    .inquiry-section .page-text{
        margin-bottom: 30px;
    }
    .inquiry-info-left,
    .inquiry-info-right{
        width:100%;
    }
    .inquiry-info-left{
        padding: 0;
        margin-bottom: 20px;
    }
    .inquiry-info-left img{
        margin:auto;
    }
    .inquiry-info-right{
        text-align: center;
    }
}
@media(max-width:576px){
}

</style>