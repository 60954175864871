var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-template', [_c('v-layout', {
    attrs: {
      "justify-center": "",
      "align-start": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100wv"
    }
  }, [_c('div', {
    attrs: {
      "id": "sub-wrap"
    }
  }, [_c('div', {
    staticClass: "sub-contents"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "sub_head_title"
  }, [_c('h1', [_vm._v("타임몰 회사소개")])]), _c('div', {
    staticClass: "about-section"
  }, [_c('div', {
    staticClass: "img-wrap"
  }, [_c('img', {
    attrs: {
      "src": "/images/timemall/about/about-img.png",
      "alt": "이미지"
    }
  })]), _c('h2', {
    staticClass: "page-title"
  }, [_c('strong', [_vm._v("한국상품권협회")]), _vm._v(" 공식 쇼핑몰")]), _c('div', {
    staticClass: "icon-plus"
  }), _c('p', {
    staticClass: "title-small6 color-point"
  }, [_vm._v("협회인증업체 누구나 자유로이 이용이 가능하며 안전결제 실시간 핀번호 전송방식 또한 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("타임몰만의 특화된 간편충전기능까지 제공해드리고 있습니다.")])]), _c('div', {
    staticClass: "about-section2"
  }, [_c('div', {
    staticClass: "about-banner"
  }, [_c('div', {
    staticClass: "about-banner__con"
  }, [_c('h3', {
    staticClass: "page-title2"
  }, [_vm._v("타임몰 "), _c('span', {
    staticClass: "color-point"
  }, [_vm._v("24시간 고객센터")]), _vm._v(" 운영으로 ")]), _c('p', {
    staticClass: "title-small6 color-dark4"
  }, [_vm._v("구입문의나 오류등 바로바로 확인이 가능하며 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("신속하게 응대해드리겠습니다.")])])])]), _c('div', {
    staticClass: "about-section3"
  }, [_c('h2', {
    staticClass: "page-title"
  }, [_c('strong', [_vm._v("타임몰 협력사")]), _vm._v(" 등록안내")]), _c('div', {
    staticClass: "title-small4 color-dark4"
  }, [_vm._v("한국상품권협회 협력사 등록시,")]), _c('ul', {
    staticClass: "about-list mx-n2 mx-lg-n4 pl-0"
  }, [_c('li', {
    staticClass: "px-2 px-lg-4"
  }, [_c('div', {
    staticClass: "about-list-icon",
    staticStyle: {
      "background-image": "url('/images/pluspin/sub/about/about-icon.png')"
    }
  }), _c('div', {
    staticClass: "about-list-txt"
  }, [_vm._v("상품권매매업 운영"), _c('br'), _c('strong', [_vm._v("노하우 컨설팅")])])]), _c('li', {
    staticClass: "px-2 px-lg-4"
  }, [_c('div', {
    staticClass: "about-list-icon",
    staticStyle: {
      "background-image": "url('/images/pluspin/sub/about/about-icon2.png')"
    }
  }), _c('div', {
    staticClass: "about-list-txt"
  }, [_vm._v("모든 상품권"), _c('br'), _c('strong', [_vm._v("구입채널 안내")])])]), _c('li', {
    staticClass: "px-2 px-lg-4"
  }, [_c('div', {
    staticClass: "about-list-icon",
    staticStyle: {
      "background-image": "url('/images/pluspin/sub/about/about-icon3.png')"
    }
  }), _c('div', {
    staticClass: "about-list-txt"
  }, [_vm._v("협회만의"), _c('br'), _c('strong', [_vm._v("특화 서비스")])])])])]), _c('div', {
    staticClass: "about-section4"
  }, [_c('div', {
    staticClass: "about-sec4-left"
  }, [_c('img', {
    attrs: {
      "src": "/images/timemall/about/about-logo.png",
      "alt": "한국상품권협회&pluspin"
    }
  })]), _c('div', {
    staticClass: "about-sec4-right"
  }, [_c('ul', {
    staticClass: "about-list2"
  }, [_c('li', [_c('strong', {
    staticClass: "about-list2__tit"
  }, [_c('i', {
    staticClass: "about-list__icon",
    staticStyle: {
      "background-image": "url('/images/pluspin/icon/icon-phone.png')"
    }
  }), _vm._v("대표번호")]), _c('span', {
    staticClass: "about-list2__txt"
  }, [_vm._v("010-4122-0080")])]), _c('li', [_c('strong', {
    staticClass: "about-list2__tit"
  }, [_c('i', {
    staticClass: "about-list__icon",
    staticStyle: {
      "background-image": "url('/images/pluspin/icon/icon-chat.png')"
    }
  }), _vm._v("카톡상담")]), _c('span', {
    staticClass: "about-list2__txt"
  }, [_vm._v("w0030")])]), _c('li', [_c('strong', {
    staticClass: "about-list2__tit"
  }, [_c('i', {
    staticClass: "about-list__icon",
    staticStyle: {
      "background-image": "url('/images/pluspin/icon/icon-edit.png')"
    }
  }), _vm._v("이메일")]), _c('span', {
    staticClass: "about-list2__txt"
  }, [_vm._v("byunpol84@naver.com")])])])])])])])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }